// 最外层盒子样式
%out-box {
  //   width: (1920rem / 100);
  position: relative;
}

// 1920设计稿，所有的值除以30；

// 内层盒子
%inside-box {
  width: (1200rem / 100);
  margin: 0 auto;
}

.map-container {
  @extend %out-box;
  background: url('https://image.bookgo.com.cn/%20webculture/jm/bg/bg_body.jpg');
  padding-bottom: (160rem / 100);


  .map-crumbs {
    @extend %inside-box;
    padding-top: (90rem / 100);
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 20px;
    color: #761F1E;
    margin-bottom: (28rem / 100);

    a {
      color: #273143;
      margin-right: 4px;
    }
  }

  .map-tab-content {
    @extend %inside-box;
  }

  .map-tab-show-more {
    @extend %inside-box;
    margin-top: (42rem / 100);

    div {
      margin: 0 auto;
      width: (149rem / 100);
      height: (50rem / 100);
      background: #761F1E;
      border-radius: (25rem / 100);
      text-align: center;
      line-height: (50rem / 100);
      font-size: (16rem / 100);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
    }

  }
}